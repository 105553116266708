import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Translate, translate } from 'react-jhipster';
import { Button } from 'reactstrap';
import { StyleSheet, PDFDownloadLink, Document, Page, View, Text, Image as PdfImage, PDFViewer, Svg, Circle } from '@react-pdf/renderer';
import { getEntityReport, getOverviewEntityReport, partialUpdateEntity } from './consultation.reducer';
import { getEntity as getPatient } from '../patient.reducer';
import { getConsultationsOverview as getOverview, getEntity as getConsultation } from '../consultation/consultation.reducer';
import { AUTHORITIES } from 'app/config/constants';
import ScalableTextarea from 'app/shared/component/scalable-textarea';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { convertDateFromServer } from 'app/shared/util/date-utils';
import CircleIcon from 'app/shared/component/circle-icon';
import { colorPathologyMap } from './consultation-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';

const PatientReport = ({ patientContext = false }) => {
  const dispatch = useAppDispatch();
  let { patientId: paramPatientId, consultationId } = useParams();
  const logguedPatientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);
  const patientId = patientContext ? logguedPatientId : paramPatientId;

  const consultation = useAppSelector(state => state.consultation.entity);
  const report = useAppSelector(state => state.consultation.entity.report);
  const navigate = useNavigate();
  const mailRef = useRef(null);
  const [mailValue, setMailValue] = useState(null);
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [download, setDowload] = useState(null);
  const [forceRender, setForceRender] = useState(false);

  console.log('patientId', patientId);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      display: 'flex',
    },
    mobilePage: {
      flexDirection: 'column',
      display: 'flex',
      visibility: 'hidden',
    },
    section: {
      flexDirection: 'row',
      display: 'flex',
      padding: '2mm',
    },
    sectionRef: {
      flexDirection: 'row',
      display: 'flex',
      padding: '2mm',
      position: 'relative',
    },
    sectionHeader: {
      flexDirection: 'row',
      display: 'flex',
      padding: '2mm',
      justifyContent: 'space-between',
      width: '190mm',
    },
    pathologySection: {
      flexDirection: 'row',
      display: 'flex',
      padding: '1mm',
      maxWidth: '140mm',
    },
    panoramicPathologySection: {
      flexDirection: 'row',
      display: 'flex',
      padding: '1mm',
      maxWidth: '190mm',
    },
    bloc: {
      flexDirection: 'column',
      display: 'flex',
      padding: '2mm',
    },
    clinicImage: {
      width: '20mm',
      height: 'auto',
      padding: '1mm',
    },
    panoramic: {
      width: '190mm',
      height: 'auto',
    },
    radio: {
      width: '50mm',
      height: 'auto',
      marginTop : '5mm'
    },
    documentTitle: {
      fontSize: '7mm',
      fontWeight: 'bold',
      padding: '1mm',
    },
    blocTitle: {
      fontSize: '6mm',
      fontWeight: 'bold',
      padding: '1mm',
    },
    text: {
      fontSize: '3mm',
      padding: '1mm',
    },
    mailText: {
      fontSize: '3mm',
      padding: '1mm',
      maxWidth: '190mm',
    },
    preText: {
      fontSize: '3mm',
      padding: '1mm',
      whiteSpace: 'pre-wrap',
    },
    boldText: {
      fontSize: '4mm',
      fontWeight: 'bold',
      padding: '1mm',
    },
    boldTextAbsolutePosition: {
      fontSize: '4mm',
      fontWeight: 'bold',
      padding: '1mm',
      position: 'absolute',
      width: '100%',
      left: '0mm',
      top: '0mm'
    },
  });

  const customSort = type => {
    const orderPhoto = [
      'decay',
      'superficialDecay',
      'intermediateDecay',
      'advancedDecay',
      'abfraction',
      'abrasion',
      'abscess',
      'unsuitableGingival',
      'periodontics',
      'normalPeriodontics',
      'moderatePeriodontics',
      'severePeriodontics',
      'tarta',
    ];

    const orderDefault = [
      'abscess',
      'decay',
      'superficialDecay',
      'intermediateDecay',
      'advancedDecay',
      'periodontics',
      'normalPeriodontics',
      'moderatePeriodontics',
      'severePeriodontics',
      'tarta',
    ];

    const order = type === 'photo' ? orderPhoto : orderDefault;

    return (a, b) => {
      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);

      if (indexA === -1 && indexB === -1) {
        return a[0].localeCompare(b[0]); // Both are not in the order list
      }
      if (indexA === -1) {
        return 1; // a is not in the order list, put it after b
      }
      if (indexB === -1) {
        return -1; // b is not in the order list, put it after a
      }
      return indexA - indexB; // Both are in the order list, compare by index
    };
  };

  useEffect(() => {
    if (patientId) {
      if (consultationId && patientId) {
        dispatch(getConsultation({ patientId, consultationId }));
      } else {
        dispatch(getOverview(patientId));
      }
    }
  }, [consultationId, dispatch, patientId]);

  useEffect(() => {
    if (patientId) {
      if (consultationId) {
        dispatch(getEntityReport({ patientId, consultationId, locale: currentLocale }));
      } else {
        dispatch(getOverviewEntityReport({ patientId, locale: currentLocale }));
      }
      dispatch(getPatient(patientId));
    }
  }, [patientId, consultationId]);

  useEffect(() => {
    if (consultation?.report) {
      const clinic = consultation.report.clinic;
      if (consultation?.report) {
        if (consultation.report.mail) {
          setMailValue(consultation.report.mail);
        } else {
          resetMail(); // build an email with prefix
        }
      }
    }
  }, [report]);

  useEffect(() => {
    handleTextAreaChange();
  }, [currentLocale, mailValue]);

  const handleTextAreaChange = () => {
    console.log('HANDLE TEXT CHANGE');
    const textarea = mailRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const resetMail = () => {
    if(consultation?.report?.recommendations?.length > 0) {
      const newReco =
        translate('dfaiApp.consultation.report.mailPrefix') +
        consultation.report.recommendations.join('\n\n').replaceAll('\\n', '\n') +
        translate('dfaiApp.consultation.report.mailSuffix');
      if (mailRef.current) {
        mailRef.current.value = newReco;
      }
      setMailValue(newReco);
    } else {
      if (mailRef.current) {
        mailRef.current.value = '';
      }
      setMailValue('');
    }
    handleTextAreaChange();
  };

  const saveMail = () => {
    dispatch(partialUpdateEntity({ patientId, entity: { id: consultation.report.consultationId, mail: mailRef.current.value } }));
    setMailValue(mailRef.current.value);
  };

  useEffect(() => {
    setForceRender(false);
    handleTextAreaChange();
  }, [forceRender]);

  const handleClick = e => {
    e.preventDefault();
    setDowload(true);
    setMailValue(mailRef.current.value);
  };

  useEffect(() => {
    if (download) {
      setTimeout(() => document.getElementById('download-link').click(), 100);
      setDowload(false);
    }
    setTimeout(() => handleTextAreaChange(), 100);
  }, [mailValue, download]);

  const getExtension = file => {
    const ext = file.split('.').pop();
    if (ext === 'jpg') {
      return 'jpeg';
    } else {
      return ext;
    }
  };

  const getTitle = () => {
    const entries = Object.entries(consultation.report.practitioners);
    let snapshot = null;
    if (entries.length > 0) {
      const [key, value] = entries[0];
      snapshot = value;
    }
    return `${translate('dfaiApp.consultation.report.title')} ${snapshot ? snapshot : ''}`;
  };

  const getRpps = () => {
    const entries = Object.entries(consultation.report.practitioners);
    if (entries.length > 0) {
      const [key, value] = entries[0];
      return key;
    }
    return '';
  };

  const getPathologyRecommendationText = (pathologyRecommendation, pathologyName, sortedTeeth) => {
    const teeth = sortedTeeth.filter(tooth => tooth !== '' && tooth !== null && tooth !== 'Unknown');
    if (pathologyRecommendation.hasOwnProperty(pathologyName) && pathologyRecommendation[pathologyName] !== '') {
      return pathologyRecommendation[pathologyName].replaceAll('\\n', '\n').replace('%s', teeth.length > 0 ? `$teeth.join(", ")}` : '');
    } else {
      return ` ${translate(`dfaiApp.pathology.${pathologyName}`)} ${teeth.length > 0 ? `(${teeth.join(', ')})` : ''}`;
    }
  };

  const MyDocument = ({ pdfExport = false }) => (
    <Document>
      <Page style={isMobile ? styles.mobilePage : styles.page}>
        <View style={styles.sectionHeader}>
          <div style={styles.bloc}>
            <Text style={styles.documentTitle}>{getTitle()}</Text>
            <Text style={styles.text}>{`${translate('dfaiApp.professional.rpps')}: ${getRpps()}`}</Text>
            <Text style={styles.text}>{`${translate('dfaiApp.clinic.detail.title')}: ${consultation.report.clinic.name}`}</Text>
            <Text style={styles.text}>{`${translate('dfaiApp.clinic.address')}: ${consultation.report.clinic.address}`}</Text>
            <Text style={styles.text}>{`${translate('dfaiApp.clinic.phone')}: ${consultation.report.clinic.phone}`}</Text>
          </div>
          <div style={styles.bloc}>
            <PdfImage
              src={`data:image/jpeg;base64,${consultation.report.images[consultation.report.clinic.id]}`}
              style={{...styles.clinicImage, objectFit: 'contain'}}
            />
            <img
              src={`data:image/jpeg;base64,${consultation.report.images[consultation.report.clinic.id]}`}
              alt="nothing"
              style={{...styles.clinicImage, objectFit: 'contain'}}
            />
          </div>
        </View>
        <View style={styles.section}>
          <div style={styles.bloc}>
            <Text style={styles.boldText}>{translate('dfaiApp.patient.name')}</Text>
            <Text style={styles.text}>
              {consultation.report.patient.firstName} {consultation.report.patient.lastName}
            </Text>
          </div>
          <div style={styles.bloc}>
            <Text style={styles.boldText}>{translate('dfaiApp.patient.birthdate')}</Text>
            <Text style={styles.text}>
              {consultation.report.patient.birthdate ? convertDateFromServer(consultation.report.patient.birthdate) : null}
            </Text>
          </div>
          <div style={styles.bloc}>
            <Text style={styles.boldText}>{translate('dfaiApp.patient.socialNumber')}</Text>
            <Text style={styles.text}>{consultation.report.patient.socialNumber}</Text>
          </div>
        </View>
        <View style={styles.section}>
          <div style={styles.bloc}>
            <Text style={styles.blocTitle}>{translate('dfaiApp.consultation.report.mail')}</Text>
            {pdfExport && mailValue && <Text style={styles.mailText}>{mailValue}</Text>}
            {!pdfExport && mailValue && (
              <ScalableTextarea
                id="mail-textarea"
                type="textarea"
                readOnly={!hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CONSULTATION])}
                style={{ height: 'auto', width: '185mm' }}
                ref={mailRef}
                onLoad={handleTextAreaChange}
                onChange={() => handleTextAreaChange()}
              >
                {mailValue}
              </ScalableTextarea>
            )}
          </div>
        </View>
        {!pdfExport && !patientContext && (
          <div>
            <Button
              className="p-1"
              color="primary"
              id="save-entity"
              size="lg"
              type="submit"
              onClick={event => {
                event.preventDefault();
                saveMail();
              }}
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              {translate('entity.action.save')}
            </Button>
            <Button
              className="p-1"
              color="info"
              id="save-entity"
              size="lg"
              type="submit"
              onClick={event => {
                event.preventDefault();
                resetMail();
              }}
            >
              {translate('entity.action.reset')}
            </Button>
          </div>
        )}
        {consultation.report.panoramic && (
          <View style={styles.section}>
            <div style={styles.bloc}>
              <Text style={styles.blocTitle}>{translate('dfaiApp.consultation.report.panoramic')}</Text>
              {pdfExport && (
                <PdfImage
                  src={`data:image/jpeg;base64,${consultation.report.images[consultation.report.panoramic.id]}`}
                  style={{...styles.panoramic, objectFit: 'contain'}}
                />
              )}
              {!pdfExport && (
                <img
                  src={`data:image/jpeg;base64,${consultation.report.images[consultation.report.panoramic.id]}`}
                  alt="nothing"
                  style={{...styles.panoramic, objectFit: 'contain'}}
                />
              )}
              <Text style={styles.text}>
                {convertDateFromServer(consultationId ? consultation.date : consultation.report.panoramic.consultation.date)}
              </Text>
              <Text style={styles.text}>
                {consultation.report.panoramic.pathologyPredictions?.map((p, index) => {
                  `${translate(`dfaiApp.pathology.${p.pathology.name}`)}${index < consultation.report.panoramic.pathologyPredictions.length - 1 ? ' ,  ' : ''}`;
                })}
              </Text>
              <Text style={styles.text}>{consultation.report.panoramic.annotation}</Text>
              {[...Object.entries(consultation.report.teethPerPathologyGroupedByImage[consultation.report.panoramic.id])]
                .sort(customSort('panoramic'))
                .map(([pathologyName, teethList]) => (
                  <div key={consultation.report.panoramic.id + pathologyName} style={styles.panoramicPathologySection}>
                    {pdfExport ? (
                      <Svg width="16" height="16" viewBox="0 0 16 16">
                        <Circle
                          cx="8"
                          cy="8"
                          r="7"
                          stroke="grey"
                          strokeWidth="1"
                          fill={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'}
                        />
                      </Svg>
                    ) : (
                      <div style={{ width: '5mm' }}>
                        <CircleIcon color={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'} />
                      </div>
                    )}
                    <Text style={styles.text}>
                      {getPathologyRecommendationText(
                        consultation.report.recommendationPerPathologyGroupedByImage[consultation.report.panoramic.id],
                        pathologyName,
                        teethList,
                      )}
                    </Text>
                  </div>
                ))}
            </div>
          </View>
        )}
        {consultation.report.orderedXrays && (
          <View style={styles.section}>
            <div style={styles.bloc}>
              <Text style={styles.blocTitle}>{translate('dfaiApp.consultation.report.xrays')}</Text>
              {consultation.report.orderedXrays.map(xray => (
                <div key={xray.id}>
                  <View style={styles.sectionRef}>
                    {pdfExport && <PdfImage src={`data:image/jpeg;base64,${consultation.report.images[xray.id]}`} style={{...styles.radio, objectFit: 'contain'}} />}
                    {!pdfExport && (
                      <img src={`data:image/jpeg;base64,${consultation.report.images[xray.id]}`} alt="nothing" style={{...styles.radio, objectFit: 'contain'}} />
                    )}
                    <Text style={styles.boldTextAbsolutePosition}>{`${translate('dfaiApp.consultation.report.xray')} ${xray.section}`}</Text>
                    <div style={styles.bloc}>
                      {consultation.report.teethPerPathologyGroupedByImage && [...Object.entries(consultation.report.teethPerPathologyGroupedByImage[xray.id])]
                        .sort(customSort('xray'))
                        .map(([pathologyName, teethList]) => (
                          <div key={xray.id + pathologyName} style={styles.pathologySection}>
                            {pdfExport ? (
                              <Svg width="16" height="16" viewBox="0 0 16 16">
                                <Circle
                                  cx="8"
                                  cy="8"
                                  r="7"
                                  stroke="grey"
                                  strokeWidth="1"
                                  fill={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'}
                                />
                              </Svg>
                            ) : (
                              <div style={{ width: '5mm' }}>
                                <CircleIcon
                                  color={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'}
                                />
                              </div>
                            )}
                            <Text style={styles.text}>
                              {getPathologyRecommendationText(
                                consultation.report.recommendationPerPathologyGroupedByImage[xray.id],
                                pathologyName,
                                teethList,
                              )}
                            </Text>
                          </div>
                        ))}
                    </div>
                  </View>
                </div>
              ))}
            </div>
          </View>
        )}
        {consultation.report.photos?.length > 0 && (
          <View style={styles.section}>
            <div style={styles.bloc}>
              <Text style={styles.blocTitle}>{translate('dfaiApp.consultation.report.photos')}</Text>
              {consultation.report.photos.map(photo => (
                <div key={photo.id}>
                  <View style={styles.section}>
                    {pdfExport && <PdfImage src={`data:image/jpeg;base64,${consultation.report.images[photo.id]}`} style={{...styles.radio, objectFit: 'contain'}} />}
                    {!pdfExport && (
                      <img src={`data:image/jpeg;base64,${consultation.report.images[photo.id]}`} alt="nothing" style={{...styles.radio, objectFit: 'contain'}} />
                    )}
                    <div style={styles.bloc}>
                      {Object.entries(consultation.report.teethPerPathologyGroupedByImage[photo.id]).map(([pathologyName, teethList]) => (
                        <div key={photo.id + pathologyName} style={styles.pathologySection}>
                          {pdfExport ? (
                            <Svg width="16" height="16" viewBox="0 0 16 16">
                              <Circle
                                cx="8"
                                cy="8"
                                r="7"
                                stroke="grey"
                                strokeWidth="1"
                                fill={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'}
                              />
                            </Svg>
                          ) : (
                            <div style={{ width: '5mm' }}>
                              <CircleIcon
                                color={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : 'white'}
                              />
                            </div>
                          )}
                          <Text style={styles.text}>
                            {getPathologyRecommendationText(
                              consultation.report.recommendationPerPathologyGroupedByImage[photo.id],
                              pathologyName,
                              teethList,
                            )}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </View>
                </div>
              ))}
            </div>
          </View>
        )}
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="d-flex flex-column">
        {!consultation?.report && <div>Loading...</div>}
        <div className="d-flex justify-content-end">
          {patientContext ? (
            <Button onClick={() => navigate(-1)} replace color="info" data-cy="entityDetailsBackButton" size="lg">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
          ) : (
            <Button
              tag={Link}
              to={consultationId ? `/patient/${patientId}/consultation/${consultationId}` : `/patient/${patientId}`}
              replace
              color="info"
              data-cy="entityDetailsBackButton"
              size="lg"
            >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
          )}
          {consultation?.report?.clinic && 
            <PDFDownloadLink className="m-2" id="download-link" document={<MyDocument pdfExport={true}/>} fileName="patient_report.pdf">
              {({ loading }) =>
                loading ? (
                  <Button color="primary" size="lg" disabled>Loading Document...</Button>
                ) : (
                  <Button color="primary" onClick={handleClick}  size="lg">{translate("dfaiApp.consultation.report.print")}</Button>
                )
              }
            </PDFDownloadLink>}
        </div>
        {consultation?.report?.clinic && <div className="d-flex justify-content-center">
          <MyDocument />
        </div>}
      </div>
    </div>
  );
};

export default PatientReport;
